.mainContainer {
  width: 100%;
  margin: 5px 0;
}

.titleText {
  display: contents;
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: "Open-Sans-bold";
  font-size: 16px;
  margin-bottom: 10px;
}

.inputAndAddBtnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 10px;
}
.inputAndAddBtnContainerWithoutTitle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.bulletContainer {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--white-color);
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
}
.bulletContainer span {
  font-family: "Open-Sans-regular";
  font-size: 14px;
  color: var(--text-color-black);
  letter-spacing: 1.4px;
  width: 100%;
  margin: 0px 10px;
}
