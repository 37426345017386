.mainContainer {
  padding: 0 20px;
  max-height: 65dvh;
  overflow-y: auto;
  box-shadow: 0px 0px 5px #0000001a;
  border-radius: 5px;
}

.inputMain {
  margin-top: 10px;
}

.footerContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.swotContainer {
  margin-top: 16px;
}

.swotTitle {
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: 'Open-Sans-bold';
  font-size: 16px;
}

.swotBody {
  margin: 5px 5px 0 5px;
  padding: 10px;
  box-shadow: 0px 0px 5px #0000001a;
  border-radius: 10px;
}
