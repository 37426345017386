.mainContainer {
  padding: 24px;
  overflow-y: scroll;
}

.headerContainer {
  padding: 10px 20px 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.btnMain {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 20px;
}

.btn {
  padding: 10px 10px;
}

.btnDiv {
  color: var(--dashboard-main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.btnDiv:hover {
  color: #66afe2;
}

.headline {
  color: var(--dashboard-main-color);
  text-transform: 'none';
  font-family: 'Open-Sans-bold' !important;
  font-size: '16px';
  text-transform: capitalize;
  margin-top: 10px;
}

.textContainer {
  padding: 10px 20px;
  margin-left: 20px;
  border: 1px solid var(--dashboard-main-color);
  border-top: none;
  border-right: none;
  white-space: pre-wrap;
  word-break: break-word;
}
