.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 10px
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.bodyContainer {
  max-height: 500px;
  overflow-y: scroll;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
}

.row:hover {
  background-color: rgb(230, 230, 230);
}

.iconContainer {
  color: var(--dashboard-main-color);
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 330px;
}