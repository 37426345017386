.mainContainer {
  padding: 24px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.headerInputContainer {
  width: 500px;
}

.headerBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin-right: 2em;
}

.mainBody {
  height: calc(100% - 80px);
  overflow-y: scroll;
}

.inputCol {
  margin-bottom: 16px;
}

.eInputCol {
  margin-bottom: 16px;
  padding-left: 3em;
}

.desc {
  box-shadow: 0px 0 5px 2px #0000000d !important;
  border-radius: 10px !important;
  border: none !important;
}

.placeholderText input::placeholder {
  text-transform: none !important;
}

.textAreaBox::placeholder {
  text-transform: none !important;
}

.imgCol {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.btnCol {
  display: flex;
  justify-content: space-evenly;
}
.btnCol button {
  background-color: var(--dashboard-main-color);
  color: var(--white-color);
  font-family: "Open-Sans-medium" !important;
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 16px !important;
  height: 44px !important;
}
.expandBtn {
  display: flex;
  align-items: center;
  margin-top: 1em;
}
.mapInput label {
  color: var(--dashboard-main-color) !important;
  text-transform: capitalize !important;
  font-family: "Open-Sans-bold" !important;
  font-size: 16px !important;
}
.mapInput div {
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 5px 2px !important;
  border-radius: 10px !important;
  border: none !important;
}
.input_main {
  margin-bottom: 15px;
}
.dropdownLabel {
  color: var(--main-color);
  font-family: "Open-Sans-bold";
  font-size: 16px;
}

.noFoundPageContainer {
  padding: 24px;
  height: calc(100vh - 95px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.noFoundPageContainer div {
  width: 80%;
  text-align: center;
}
.noFoundPageContainer p {
  width: 80%;
  margin: 0 auto 16px auto;
  font-family: "Open-Sans-medium";
}
