.container_main {
  padding: 20px 30px 30px 30px;
  height: calc(100vh - 130px) !important;
  overflow: auto;
}

.main_heading {
  margin-bottom: 60px;
  justify-content: space-between;
  align-items: center;
}

.addEmailTemplateModal_main {
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.addEmailTemplateModal_main h4 {
  text-align: center;
  margin-bottom: 40px;
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-bold';
}

.btn_main {
  margin-top: 20px;
}

.btn {
  width: fit-content;
  padding: 17px 30px;
  float: right;
}

.addProject_row {
  row-gap: 30px;
}

.input_row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.subject_input {
  grid-column: 1 / 5;
}

.textEditor {
  width: 100%;
  grid-column: 2 / 5;
  grid-row: 3 / 6;
}

.textEditor p {
  margin: 0px !important;
}

.texteditor_body {
  border-top: none !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  height: 450px !important;
}

.var_section {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 450px;
  height: 100%;
  overflow-y: auto;
}

.var_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  font-size: 16px;
}

@media screen and (max-width: 1440px) {
  .textEditor {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
  }

  .var_section {
    grid-column: 1 / 3;
  }
}

@media screen and (max-width: 1200px) {
  .textEditor {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
  }

  .var_section {
    grid-column: 1 / 3;
  }
}

