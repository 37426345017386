.removeBtn {
  margin-top: 35px;
  padding: 12px 16px;
}

.addBtn {
  margin-top: 35px;
}

.spliter {
  border-bottom: 1px solid #c4c4c4;
}
