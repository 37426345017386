.heroSection {
    background-size: 100% 100%;
    z-index: 0;
    position: relative;
    background-color: #020101;
  }
  
  .heroSection::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(240deg, #0075C8 20%, #000000 100%);
    opacity: 0.89;
    z-index: -1;
  }
  
  .container {
    max-width: 67% !important;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 1280px) {
    .container {
      max-width: 75% !important;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .container {
      max-width: 80% !important;
    }
  }
  
  @media screen and (max-width: 768px) {
    .container {
      max-width: 90% !important;
    }
  }
  
  @media screen and (max-width: 375px) {
    .container {
      max-width: 96% !important;
    }
  }