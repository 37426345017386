.mainComtainer {
  padding: 25px 30px;
}
.header_main {
  margin-bottom: 20px;
}
.header_main h4 {
  font-size: 35px !important;
  color: var(--dashboard-main-color);
  font-family: "Bebas-Neue-bold" !important;
}
.inputDiv {
  margin-bottom: 24px;
}
.btnDiv {
  display: flex;
  justify-content: center;
}
.btnDiv button {
  font-size: 16px;
  border-radius: 10px;
}
