.container {
  display: flex;
  flex-direction: column;
}

.label {
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: 'Open-Sans-bold';
  font-size: 16px;
  margin-bottom: 10px;
}

.disabled {
  color: #ababab;
}

/* On Hover */
.dropdown:hover {
  border: 1px solid black !important;
}

/* on Focus */
.dropdown:focus {
  border: 1px solid black;
}

/* On Active */
.dropdown:active {
  border: 1px solid black;
}

/* On Disabled */
.dropdown:disabled {
  background-color: #f6f6f6;
  color: #b5b5b5;
  border: 1px solid #e5e5e5;
}

.dropdownContainer {
  position: relative;
}