.listingHead {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
}

.listingHead h4 {
  font-family: 'Bebas-Neue-bold';
  font-size: 35px;
  color: #242342;
  margin-bottom: 0;
}

.container {
  height: calc(100vh - 153px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.btn {
  padding: 0.7em 1em;
  margin: 0.5em 0;
}

.editContainer {
  margin: 24px 24px 0 0;
}

.toolBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.linkText {
  text-decoration: underline;
  cursor: pointer;
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-medium' !important;
}

.linkText:hover {
  color: #7c09ffb3;
  text-decoration: none;
}

.btnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.icon {
  color: var(--dashboard-main-color);
}

.icon:hover {
  opacity: 0.7;
}

.warningIcon {
  color: rgb(248, 73, 73);
}

.inputMain {
  width: 300px;
}