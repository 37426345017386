.container {
  width: 50%;
}

.tableContainer {
  height: calc(100vh - 300px);
  overflow-y: scroll;
  width: 100%;
}

.btnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px;
  gap: 20px;
}