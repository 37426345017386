.box {
    user-select: none;
    gap: 15px;
}

.box label {
    font-size: 18px;
    margin-top: 20px;
    color: var(--label-color);
    font-family: "Open-Sans-regular";
}

.csvBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 125px;
    border-radius: 10px;
    box-shadow: 0px 0 5px 2px #0000000d;
    position: relative;
    flex-direction: column;
    position: relative;
}

.csvBox .viewBtnBox {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background-color: #54545447;
    height: 100%;
    z-index: 1;
    border-radius: inherit;
    opacity: 0;
    padding: 8px;
}

.csvBox:hover .viewBtnBox {
    opacity: 1;
    transition: opacity 0.3s;
}

.uploadImageBox {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 0 5px 2px #0000000d;
    position: relative;
}

.uploadBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.uploadBox .icon {
    height: 75px;
    width: 75px;
    color: var(--label-color);
}

.uploadBox .uploadIcon {
    position: absolute;
    right: 12px;
    top: 10px;
    background-color: var(--dashboard-main-color);
    width: 43px;
    height: 43px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.uploadBox .uploadIcon svg {
    color: var(--white-color);
    font-size: 20px;
}

/* Image Uploaded */

.editAndDelete {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    z-index: 1;
}

.icon {
    background-color: var(--dashboard-main-color);
    padding: 6px;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0px 0px 3px 1px #afa8a8;
    display: block;
}

.icon svg {
    color: var(--white-color);
    vertical-align: middle;
    width: 16px;
    height: 16px;
    display: inherit;
}

.closeIcon {
    position: absolute;
    top: 10px;
    left: 12px;
    background-color: var(--dashboard-main-color);
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 3px 1px #afa8a8;
    z-index: 2;
}

.closeIcon svg {
    color: var(--white-color);
    vertical-align: middle;
    display: flex;
}

.uploadText {
    display: block;
    font-size: 14px;
    font-family: "Open-Sans-bold";
}

/* Render */
.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    border-radius: inherit;
}

.typeIcon {
    font-size: 40px;
    color: var(--dashboard-main-color);
}