.btnMain {
  width: 100%;
  display: flex;
  justify-content: right;
}

.btn {
  padding: 10px 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--dashboard-main-color);
  cursor: pointer;
}

.btnDiv:hover {
  color: #66afe2;
}

.msgContainer {
  width: calc(100% - 5em);
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  white-space: pre-wrap;
  word-break: break-word;
}
