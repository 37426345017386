.mainContainer {
  height: auto;
  width: 100%;
}

.itemsContainer {
  height: calc(100vh - 110px);
  padding-top: 25px;
  overflow-y: scroll;
  background: var(--dashboard-main-color);
  box-shadow: 0px 4px 20px 10px rgb(0 0 0 / 6%);
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.6);
  border-radius: 5px;
}


.logoContainer {
  height: 102px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  overflow: hidden;
}

.logoContainer img {
  width: 150px;
  height: auto;
}

.listItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-block: 7px;
  padding-left: 20px;
  margin-block: 8px;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.listItemContainer:hover {
  text-decoration: none;
}

.marginZero {
  margin-block: 0px !important;
}

.listItemContainer span {
  font-family: "Open-Sans-regular";
  font-size: 16px;
  color: var(--sidebar-text-color);
  margin-left: 18px;
}

.dropDownIcon {
  position: absolute;
  right: 15px;
  top: 22%;
}

.innerItemContainer {
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 60px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.innerItemContainer span {
  font-family: "Open-Sans-regular" !important;
  font-size: 14px;
  color: var(--sidebar-text-color);
  margin-left: 18px;
}

.active span {
  color: #ffffff;
  font-family: "Open-Sans-SemiBold";
}

.active svg {
  color: #ffffff !important;
}

.inactive span {
  color: #d9d4d4;
}

.inactive svg {
  color: #d9d4d4 !important;
}

.subActive span {
  color: #ffffff;

}

.subActive svg {
  color: #ffffff !important;
}

::-webkit-scrollbar {
  /* display: none; */
}