.mainContainer {
  height: calc(100vh - 130px);
  padding: 0 15px;
}

.container {
  padding-right: 5px;
  height: calc(100vh - 155px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.interests_head {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
}

.interests_head h4 {
  font-family: 'Bebas-Neue-bold';
  font-size: 35px;
  color: #242342;
  margin-bottom: 0;
}

.actions_btn {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.detail_btn {
  padding: 10px;
}

.edit_btn {
  padding: 0 20px;
  background-color: transparent;
  color: var(--text-color-black);
  border: 1px solid #d4d4d4;
}

.paginationDiv {
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px 20px 30px;
}

.teamMemberImgDiv {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  border: 1px solid #707070;
  position: absolute;
  top: -22px;
}

.teamMemberImgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.countMember {
  position: absolute;
  font-size: 12px;
}

.phoneNumber {
  cursor: pointer;
  font-family: 'Open-Sans-medium';
  font-size: 14px;
  color: var(--dashboard-text-color);
  text-transform: capitalize;
}

.btnContainer {
  background: var(--dashboard-main-color);
  margin-right: 5px;
  border-radius: 10px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.btnContainer svg {
  cursor: pointer !important;
}

.active:hover {
  opacity: 0.8;
}

.disable svg {
  opacity: 0.4;
}

.emailText {
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-size: 14px;
}

.inputDiv {
  display: flex;
  align-items: center;
  gap: 12px;
}

.filterDropDown {
  display: none;
}

.inquired {
  background-color: #80808061 !important;
  color: #707070 !important;
}

.ndaSubmit {
  background-color: #ffa5003b !important;
  color: orange;
}

.ndaSignedColor {
  background-color: #cdf0cd !important;
  color: green !important;
}

.ndaApproved {
  background-color: #7c09ff4a !important;
  color: #7c09ffb3 !important;
}

.VIP {
  background-color: #f58b8b61 !important;
  color: #f57979 !important;
}

.closed {
  background-color: #2a6274a1 !important;
  color: #0c445e !important;
}

.colorDiv {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 8px;
  white-space: pre-wrap;
}

.toggleBtnMain {
  display: flex;
  gap: 10px;
  align-items: center;
}

.toggleBtnMain p {
  margin-bottom: 0px;
}
