.container {
  height: calc(100vh - 103px);
  padding: 15px;
  overflow-y: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}

.header h4 {
  font-family: 'Bebas-Neue-bold';
  font-size: 35px;
  color: #242342;
  margin-bottom: 0;
}

.body {
  padding: 15px;
  width: 100%;
}
