.add_interest_main {
  padding: 0 20px;
}
.add_interest_btn {
  width:100%;
  background-color: var(--dashboard-main-color);
  font-family: "Open-Sans-bold" !important;
  font-size: 18px !important;
  padding: 10px 0;
}
.add_interest_main h4 {
  color: var(--dashboard-main-color);
  font-family: "Open-Sans-bold" !important;
  text-align: center;
  margin-bottom: 30px;
}
.input_main {
  margin-top: 20px;
}
.add_interest_btn_main {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}
.titleText {
  display: contents;
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: "Open-Sans-bold";
  font-size: 16px;
  margin-bottom: 10px;
}