.addEmailTemplateModal_main {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-height: 70dvh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
  padding-left: 8px;
}

.btn_main {
  margin-top: 8px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.btn {
  width: fit-content;
  padding: 17px 30px;
  float: right;
}

.addProject_row {
  row-gap: 30px;
}

.input_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 16px;
}

.col_span_2 {
  grid-column: span 2;
}

.input_row textarea {
  height: 80px;
}
