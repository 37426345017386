.container {
  height: calc(100vh - 113px);
  overflow-y: scroll;
}

.mainContainer {
  padding: 24px 24px 0 24px;
}

.headerContainer {
  padding: 10px 20px 0 20px;
  text-transform: capitalize;
}

.headerItem {
  min-width: 300px;
}

.headerItemSearch {
  width: 100%;
}

.headerContainer h3 {
  font-family: 'Open-Sans-bold' !important;
  font-size: 35px;
  color: var(--dashboard-main-color);
}

.teamMemberImgDiv {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  border: 1px solid #707070;
  position: absolute;
  top: -22px;
}

.teamMemberImgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.countMember {
  position: absolute;
  left: 125px;
  top: -7px;
  font-size: 12px;
}

.threeDots {
  cursor: pointer;
}

.paginationDiv {
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px 20px 30px;
}

.addProjectBtn {
  background-color: var(--dashboard-main-color);
  color: var(--white-color);
  padding: 0;
  width: 143px;
  height: 43px;
  border-radius: 3px;
  font-size: 14px !important;
  font-family: 'Open-Sans-medium' !important;
  margin-left: 16px;
}

.dropDown_main {
  display: flex;
  justify-content: center;
}

.treeviewContainer {
  width: 100%;
  padding: 0 15px;
  overflow-y: scroll;
}

.xScrollContainer {
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 14px;
}

.btnsDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  min-width: 800px;
  width: 100%;
}

.folderBox {
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
}

.folderInnerBox {
  width: 180px;
  margin-bottom: 16px;
}

/* no folder */
.noFolderMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  width: 100%;
}

.noFolderComp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  border-radius: 50%;
  padding: 20px 0px;
  height: 300px;
  background: #00000008;
}

.noFolderComp svg {
  font-size: 120px;
  color: var(--dashboard-main-color);
}

.noFolderComp p {
  font-size: 18px;
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-regular';
  max-width: 80%;
  text-align: center;
}

.checkbox {
  /* background-color: var(--dashboard-main-color); */
  color: var(--dashboard-main-color);
  cursor: pointer;
}

/*  */

.mainBody {
  padding: 0 24px;
}

.treeContainer {
  margin-top: 25px;
  overflow-y: scroll;
}

.listBody {
  width: 100%;
  margin-top: 20px;
}

.listContainer {
  width: 100%;
  overflow-y: scroll;
  padding: 0 0 0 30px;
}

.uploadContainer {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.uploadContainer div {
  cursor: pointer;
}

.itemsContainer {
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative; /* For future positioning */
  cursor: pointer;
}

.itemsContainer svg {
  margin: 0 5px;
  color: var(--dashboard-main-color);
}

.checkbox {
  margin-right: 8px; /* Space between checkbox and icon */
}

.roomIcons {
  margin-right: 8px; /* Space between icon and name */
  width: 40px;
}

.itemName {
  display: inline-block;
  width: calc(100% - 104px);
  /* flex-grow: 1; */
  overflow: hidden; /* Hide overflow text */
  white-space: nowrap; /* Prevent text wrap */
  text-overflow: ellipsis; /* Show ellipsis for overflowing text */
}

.descContainer {
  width: calc(100% - 104px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  text-align: left;
}

.descContainer div {
  max-width: 100%;
  margin: 0.2em;
}

.descName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.fileName:hover {
  color: var(--ck-color-focus-border);
}

.descDate {
  font-size: 0.7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.menuIcon {
  margin-left: 8px; /* Space between name and menu icon */
  width: 30px;
}

.menuItem {
  font-size: 1em;
}

.mIcons {
  margin-right: 12px;
}

.menuDelete {
  color: red !important;
}

.nonLink {
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: black;
}

.nonLink:hover {
  color: black;
}

.linkText {
  text-decoration: underline;
  cursor: pointer;
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-medium' !important;
}

.linkText:hover {
  color: #7c09ffb3;
  text-decoration: none;
}
