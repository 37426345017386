.mainContainer {
  width: 100%;
  margin: 5px 0;
}

.titleText {
  display: contents;
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: 'Open-Sans-bold';
  font-size: 16px;
  margin-bottom: 10px;
}

.inputAndAddBtnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 10px;
}

.inputAndAddBtnContainerWithoutTitle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.inputBoxContainer {
  padding: 10px 0;
}

.inputBox {
  margin: 20px 0;
}
