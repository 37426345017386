.mainContainer {
  padding: 24px;
  height: calc(100vh - 103px);
  overflow-y: scroll;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.headerContainer h3 {
  font-family: 'Open-Sans-bold' !important;
  font-size: 35px;
  color: var(--dashboard-main-color);
}

.inputCol {
  margin-bottom: 16px;
}

.desc {
  box-shadow: 0px 0 5px 2px #0000000d !important;
  border-radius: 10px !important;
  border: none !important;
}

.imgCol {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.updateProfileBtn {
  margin-top: 10px;
  border-radius: 10px;
  background-color: var(--white-color);
  color: var(--dashboard-main-color);
  border: 1px solid var(--dashboard-main-color);
  padding: 7px 20px;
}

.imgBox {
  width: 200px;
}

.btnCol {
  display: flex;
  justify-content: center;
}

.btnCol button {
  background-color: var(--dashboard-main-color);
  color: var(--white-color);
  font-family: 'Open-Sans-medium' !important;
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 16px !important;
  height: 44px !important;
}

.mapInput label {
  color: var(--dashboard-main-color) !important;
  text-transform: capitalize !important;
  font-family: 'Open-Sans-bold' !important;
  font-size: 16px !important;
}

.mapInput div {
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 5px 2px !important;
  border-radius: 10px !important;
  border: none !important;
}
