.container_main {
  padding: 20px 30px 30px 30px;
  height: calc(100vh - 130px) !important;
  overflow: auto;
}

.main_heading {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addEmailTemplateModal_main {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.addEmailTemplateModal_main h4 {
  text-align: center;
  margin-bottom: 40px;
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-bold';
}

.btn_main {
  margin-top: 10px;
}

.btn {
  width: fit-content;
  padding: 17px 30px;
  float: right;
}

.addProject_row {
  row-gap: 30px;
}

.input_row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
}

.textEditor {
  width: 100%;
  grid-column: 2 / 5;
  grid-row: 2 / 3;
}

.se-navigation {
  display: none !important;
  opacity: 0 !important;
}

.texteditor_body {
  border-top: none !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  height: 500px !important;
}

.var_section {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.variables {
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.var_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  font-size: 16px;
}

.action_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.action_button button {
  border: none;
  padding: 0;
  background-color: transparent;
  width: 22px;
}

.textEditor p {
  margin: 0px !important;
}

@media screen and (max-width: 1440px) {
  .textEditor {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
  }

  .var_section {
    grid-column: 1 / 3;
  }
}

@media screen and (max-width: 1200px) {
  .textEditor {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
  }

  .var_section {
    grid-column: 1 / 3;
  }
}
