.mainContainer {
  padding: 24px;
  overflow-y: scroll;
}

.headerContainer {
  padding: 10px 20px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.headerContainer h4 {
  font-family: 'Open-Sans-bold' !important;
  font-size: 35px;
  color: var(--dashboard-main-color);
}

.btnMain {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 20px
}

.btn {
  padding: 10px 10px;
}

.btnDiv {
  color: var(--dashboard-main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.btnDiv:hover {
  color: #66afe2;
}