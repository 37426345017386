.navbarContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 2px solid #f0f0f0;
  padding-block: 13.5px;
  max-width: 100%;
  user-select: none;
  height: 103px;
}

svg {
  display: inline-block;
  vertical-align: top;
}
.Logout {
  margin-top: 10px;
}

.profileImg {
  background-color: #d6d6d6;
  border: 2px solid #929eaa;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
}

.notifyIconDiv {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.notifyIcon {
  cursor: pointer;
  font-size: 35px;
}

.notifyoverlayDiv {
  top: 7px !important;
  left: -10px !important;
  z-index: 2;
  background-color: var(--white-color);
  box-shadow: 1px 1px 8px 0px #d5d5d5;
  border-radius: 20px;
}

.notifyoverlayDiv ul {
  padding: 20px 20px;
  margin-bottom: 0px;
}

.notifyoverlayDiv ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: var(--dashboard-main-color);
  user-select: none;
  cursor: pointer;
}

.notifyoverlayDiv ul li:first-child {
  margin-bottom: 10px;
}

.notifyoverlayDiv ul li svg {
  vertical-align: middle;
}

.container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.userName {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.userName p {
  font-size: 20px;
  font-family: "";
  margin-bottom: 0px;
  text-transform: capitalize;
  font-family: "Open-Sans-regular";
}

.signup {
  background-color: transparent;
  color: var(--dashboard-main-color);
  border: 1px solid var(--dashboard-main-color);
  padding: 0;
  height: 43px;
  width: 120px;
}

.login {
  margin-right: 16px;
  padding: 0;
  height: 43px;
  width: 120px;
}

@media (max-width: 1080px) {
  .notifyIconDiv {
    margin-right: 16px;
    width: 40px;
    height: 40px;
  }

  .notifyIcon {
    font-size: 20px;
  }

  .profileImg {
    width: 40px;
    height: 40px;
  }
}
