.GiHamburgerMenu {
  font-size: 30px;
  color: var(--dashboard-main-color);
  position: absolute;
  top: 25px;
  left: 20px;
}

.shortDiv {
  width: 100px;
}

.sidebarDiv {
  width: 280px;
}

.contentDiv {
  width: calc(100% - 280px);
}

/* @media screen and (max-width: 1440px) {
  .sidebarDiv {
    width: 300px;
  }

  .contentDiv {
    width: calc(100% - 300px);
  }
}

@media screen and (max-width: 1220px) {
  .sidebarDiv {
    width: 280px;
  }

  .contentDiv {
    width: calc(100% - 280px);
  }
}

@media screen and (max-width: 1100px) {
  .sidebarDiv {
    width: 250px;
  }

  .contentDiv {
    width: calc(100% - 250px);
  }
} */
