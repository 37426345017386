.add_task_main {
  padding: 0 20px;
  max-height: 65dvh;
  overflow-y: auto;
}

.add_task_main h4 {
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-bold' !important;
  text-align: center;
  margin-bottom: 30px;
}

.input_main {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.labelText {
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-bold';
  font-size: 16px;
  margin: 0;
}

.searchBar {
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchBar h4 {
  color: var(--dashboard-main-color);
  font-family: 'Open-Sans-bold';
  font-size: 20px;
  margin: 0;
  text-transform: capitalize;
}

.searchBar .inputContainer {
  width: 400px;
}

.inputPassContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  position: relative;
  background-color: var(--white-color);
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
  padding: 8px 16px;
}

.inputBox {
  font-family: 'Open-Sans-regular';
  font-size: 16px;
  color: var(--text-color-black);
  letter-spacing: 1.4px;
  border-radius: inherit;
  background-color: inherit;
  width: 100%;
  margin: 0;
}

