.mainContainer {
  padding: 15px;
  height: calc(100vh - 103px);
}

.listingHead {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}

.listingHead h4 {
  font-family: 'Bebas-Neue-bold';
  font-size: 35px;
  color: #242342;
  margin-bottom: 0;
}

.container {
  height: calc(100vh - 220px);
  overflow-y: scroll;
}

.editContainer {
  height: calc(100vh - 270px);
  overflow-y: scroll;
}

.btn {
  padding: 0.7em 1em;
  margin: 0.5em 0;
}

.containerWhenGroup {
  height: calc(100vh - 290px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

.toolBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin-left: 15px;
}