.PreViewListingContainer {
  width: 100%;
  height: calc(100vh - 105px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.page {
  width: 100%;
}

.page h3 {
  text-align: center;
  margin-bottom: 8px;
  font-size: 50px;
  text-transform: capitalize;
}

.HeroSectionBgImage {
  background-image: url('../../assets/images/detail-section-bg.jpeg') !important;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 450px;
}

.HeroSectionBgImage::before {
  opacity: 0.75;
}

.heroHeader {
  color: #ffffff;
  font-family: 'Bebas-Neue-bold';
  font-size: 60px;
  line-height: normal !important;
}

.heroContainer {
  padding: 50px 0;
}

.heroSubHeader {
  color: #ffffff;
  font-style: italic;
  margin-bottom: 20px;
}

.heroContent {
  color: #ffffff;
  margin: 20px 0px 0 0;
}

.heroBtn {
  background-color: white;
  color: var(--main-color);
  font-size: 24px !important;
}

.heroBtn:hover {
  background-color: var(--main-color);
  color: white;
}

.mainContainer {
}

.contentSection {
  padding: 54px 0 0 0;
}

.title {
  color: var(--main-color);
  text-align: center;
  font-family: 'Bebas-Neue-bold';
  word-break: break-all;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.description {
  font-family: 'Open-Sans-semiBold';
  word-break: break-word;
  font-size: 16px;
  white-space: pre-line;
}

.StatsBgImage {
  background-image: url('../../assets/images/stats-bg.jpg') !important;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 450px;
}

.StatsBgImage::before {
  opacity: 0.83;
}

.statsContainer {
  align-items: center;
  width: 100% !important;
}

.statsContainer p {
  font-size: 40px !important;
  color: var(--white-color);
  text-align: center;
  margin-top: 10px;
  font-family: 'Bebas-Neue-bold' !important;
}

.statsContainer span {
  font-size: 14px !important;
  color: var(--white-color);
  text-align: center;
  font-family: 'Open-Sans-regular' !important;
}

.statsContainer svg {
  font-size: 60px !important;
}

.mainStatsDiv {
  margin-bottom: 24px;
}

.ProsConsBgImage {
  background-image: url('../../assets/images/cons-bg.jpg') !important;
  background-size: cover;
  background-position: center center;
  /* min-height: 450px; */
}

.ProsConsBgImage::before {
}

.consContainer {
  padding: 10px;
}

.prosCol div {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  /* justify-content: center; */
}

.prosCol p {
  color: white;
  font-size: 16px;
  font-family: 'Open-Sans-bold' !important;
  margin-bottom: 4px;
}

.prosCol svg {
  color: white;
  margin-top: 3px;
  font-size: 24px;
}

.prosCol h3 {
  color: white;
  text-align: center;
  margin-bottom: 12px;
  font-size: 48px;
}

/*  */
/* Common  */
.jCenter {
  display: flex;
  justify-content: center;
}

.aCenter {
  display: flex;
  align-items: center;
}

/* End of Common */

/* Financing Options */

.financingOptionsSection {
  min-height: 400px;
  display: flex;
  align-items: center;

  background-image: url('../../assets/images/financing-bg.jpg') !important;
  background-size: cover;
  background-position: center center;
}

.financingOptionsSection::before {
  opacity: 0.83;
}

.financing {
}

.financing h3 {
  color: var(--main-color);
  padding: 20px 0;
  margin-bottom: 0;
}

/* Financing Options */

/* business highlights */
.businessHighlightsSection {
  margin-top: 54px;
  min-height: 400px;
  /* display: flex; */
  /* align-items: center; */

  background-image: url('../../assets/images/business-highlights-bg.jpg') !important;
  background-size: cover;
  background-position: center center;
}

.businessHighlightsSection::before {
  opacity: 0.83;
}

.businessHighlightsSection h3 {
  color: var(--white-color);
  margin-bottom: 18px;
}

.iconWithTitle {
  display: flex;
  text-transform: uppercase;
  padding: 0 20px;
  /* align-items: center; */
  /* justify-content: center; */
}

.iconWithTitle svg {
  color: var(--white-color);
  font-size: 15px;
  display: inline-flex;
  flex-shrink: 0;
  vertical-align: middle;
}

.iconWithTitle p {
  font-family: 'Open Sans', Sans-serif;
  color: var(--white-color);
  font-size: 15px;
  margin-bottom: 0px;
  margin-left: 15px;
  line-height: 1.3;
}

.iconWithTitle {
  margin-bottom: 20px;
}

/* business highlights */
/* Business Location */
.locationSection {
  background-color: var(--text-color-gray);
}

.locationSection h3 {
  padding-top: 70px;
  margin-bottom: 50px;
  color: var(--main-color);
}

.locationSection iframe {
  width: 100%;
  height: 600px;
}

/* Business Location */

/* Online Presence */

.onlinePresenceSection {
  min-height: 400px;
  display: flex;
  align-items: center;
  background-image: url('../../assets/images/presence-bg.jpg') !important;
  background-size: cover;
  background-position: center center;
}

.onlinePresenceSection::before {
  opacity: 0.83;
}

.onlinePresence h3 {
  margin: 50px 0px;
  color: var(--main-color);
}

.socailCard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.socailCard1 {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.socailCard > div,
.socailCard1 > div {
  cursor: pointer;
  flex-basis: 20%;
  margin-bottom: 16px;
}

/* End of Online Presence */

/* Property Info Box */
.propertyInfoBox {
  /* padding-bottom: 20px; */
  margin: 0px;
}

.propertyInfoBox h3 {
  margin-bottom: 20px;
  color: var(--main-color);
}

/* End of Property Info Box */

/* Property Info Main */
.propertyInfoContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.propertyInfoMain {
  /* margin: 20px 0px 50px 0px; */
}

.propertyInfoMain .header {
  padding: 40px 0px;
  /* background-color: var(--text-color-gray); */
  background-color: var(--white-color);

  /* margin-bottom: 40px; */
}

.bgDivGreyColor {
  background-color: var(--text-color-gray);
  padding: 40px 0;
}

.bgDivwhiteColor {
  background-color: var(--white-color);
  padding: 40px 0;
}

.propertyInfoMain h3 {
  color: var(--main-color);
  margin-bottom: 0px;
}

.propertyInfoMain .header .headAndTitle {
  margin-top: 20px;
  align-items: center;
}

.propertyInfoMain .header .headAndTitle p {
  white-space: pre-wrap;

  margin-bottom: 0px;
}

.propertyInfoMain .header .headAndTitle h5 {
  margin-bottom: 0px;
  color: var(--main-color);
  font-size: 30px;
  white-space: pre-wrap;
}

.propertyInfoMain .imagesBoxes {
  margin: 50px 0;
}

.mb16 {
  margin-bottom: 16px;
}

.propertyInfoMain .imgCol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.propertyInfoMain .imgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.propertyInfoMain .imgDiv img {
  width: 100%;
  height: 100%;
}

/* Property Info Main */

/* Property Info Section */
.propertyInfoSection {
}

.propertyInfoSection .btmSeparator {
  margin-top: 20px;
  border-bottom: 1px solid var(--main-color);
}

.propertyInfoSection .iconDiv {
  display: flex;
  justify-content: center;
}

.propertyInfoSection .iconBox > div {
  border-color: var(--main-color);
  transition: all 0.3s;
}

.propertyInfoSection .iconBox svg {
  color: var(--main-color);
}

.propertyInfoSection .iconBox > div:hover {
  background-color: var(--main-color);
  border-color: var(--text-color-gray);
}

.propertyInfoSection .iconBox > div:hover svg {
  color: var(--text-color-gray);
}

.hrsOfOperation p {
  font-size: 15px;
  color: var(--text-color-black);
  font-family: 'Open-Sans-regular';
}

.employeeNumberBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* End of Property Info Section */

/* Recent Improvements */
.recentImprCol {
}

.recentImprCol .yearBox {
  padding: 5px 0px 5px 0px;
  border: 2px solid var(--white-color);
  color: var(--main-color);
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Open-Sans-bold';
  text-align: center;
  line-height: 1;
}

.recentImprCol ul {
  padding-left: 0px;
}

.recentImprCol ul li {
  margin-top: 20px;
  display: flex;
}

.recentImprCol ul li p {
  font-size: 14px;
  text-transform: capitalize;
  color: var(--main-color);
  font-family: 'Open-Sans-regular';
  margin-bottom: 0px;
}

.recentImprCol li svg {
  margin-right: 15px;
  font-size: 15px;
  vertical-align: middle;
  color: var(--main-color);
  flex-shrink: 0;
  margin-top: 4px;
}

.recentImprIcon .iconDiv {
  height: 100%;
  align-items: center;
}

.recentImprIcon .iconDiv svg {
  font-size: 80px;
}

.recentImprIcon .iconBox > div {
  border: none;
}

.recentImprIcon .iconBox > div:hover {
  background-color: unset;
  border-color: unset;
}

.recentImprIcon .iconBox > div:hover svg {
  color: var(--main-color);
}

/* Recent Improvements */

/* Business Galley */
.businessGallerySection {
  padding-top: 54px;
}

.businessGallerySection h3 {
  color: var(--main-color);
}

.businessGallerySection .imagesBox {
  display: flex;
}

.businessGallerySection .carousel {
  margin-top: 30px;
}

.businessGallerySection .imgBox {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1cece;
  overflow-y: scroll;
}

.businessGallerySection .imgSingleBox {
  width: 100%;
  height: 400px;
}

.businessGallerySection .imgSingleBox img {
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: contain;
}

.businessGallerySection .imgBox img {
  width: 100%;
  height: auto;
  background-color: #d1cece;
  /* object-fit: cover;
  object-position: top center; */
}

/* End of Business Galley */

/* Contact Us */
.contactUs {
  margin: 70px 0;
}

.contactUs h3 {
  color: var(--main-color);
  margin-bottom: 24px;
}

.contactUs .imgDiv {
  width: 199px;
  height: 199px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  border: 2px solid #929eaa;
  background-color: #d6d6d6;
}

.contactUs .imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.contactUs .details {
  display: flex;
}

.contactUs .details .right {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.contactUs .details .right p {
  font-size: 15px;
  color: var(--text-color-black);
  margin-bottom: 10px;
}

.contactUs .details .right h5 {
  font-size: 30px;
  color: var(--main-color);
  text-transform: uppercase;
  font-family: 'Bebas-Neue-bold';
}

.contactUs .details .right .iconWithValue {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none;
}

.contactUs .details .right .iconWithValue svg {
  color: var(--main-color);
  font-size: 22px;
  flex-shrink: 0;
}

.contactUs .details .right .iconWithValue p {
  padding-left: 6px;
  color: var(--main-color);
  font-size: 16px;
  margin-bottom: 0px;
}

.contactUs .right .contactInfo {
  margin-bottom: 10px;
}

/* Contact Us */

/* Comps */
/* Circle Icon With Title */
.circleIconWithTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: max-content;
}

.circleIconWithTitle > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 106px;
  height: 106px;
  border: 3px solid var(--white-color);
}

.circleIconWithTitle svg {
  font-size: 60px;
  color: var(--white-color);
}

.circleIconWithTitle p {
  font-size: 18px;
  color: var(--white-color);
  text-align: center;
  margin-top: 10px;
  font-family: 'Roboto Slab', Sans-serif;
  font-family: 'Open-Sans-regular';
  line-height: 1;
}

/*  */
/* Comps */
.finanacialsAnalysis {
  margin-block: 70px;
}

.finanacialsAnalysis h3 {
  color: var(--main-color);
}

.finanacialsAnalysisImgBox {
  margin-bottom: 24px;
  width: 100%;
  height: 250px;
  /* max-height: 600px; */
}

.finanacialsAnalysisImgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.finanacials {
  padding: 50px 0;
  background-color: #eee;
}

.finanacials h3 {
  color: var(--main-color);
}

/* financial csv */
.csvTable {
  margin-bottom: 20px;
}

.csvTable table {
  position: relative;
}

.csvTable thead {
  background-color: #d9edf7;
  height: 50px;
  position: static;
  left: 0;
  width: 100%;
  display: table;
  top: 0;
}

.csvTable thead th {
  text-transform: capitalize;
  font-family: 'Open-Sans-bold';
}

.csvTable tbody {
  width: 100%;
  display: table;
}

.csvTable tbody td {
  font-size: 12px;
  font-family: 'Open-Sans-regular';
  padding: 8px;
}

.csvTable tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.csvTable tbody tr:nth-child(even) {
  background-color: #fff;
  border-color: #dcd7ca;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.column1 {
  width: 16.66%;
  padding-left: 10px;
}

.column2 {
  width: 16.66%;
}

.column3 {
  width: 16.66%;
}

.column4 {
  width: 16.66%;
}

.column5 {
  width: 16.66%;
}

.column6 {
  width: 16.66%;
  padding-right: 10px;
}

.mt20 {
  margin-top: 20px;
}

.finanacialsImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  row-gap: 16px;
  margin-top: 20px;
}

.finanacialsImages .imgDiv {
  flex-basis: calc(33.33% - 16px);
  height: 300px;
  cursor: pointer;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1cece;
}

.finanacialsImages .imgDiv::-webkit-scrollbar {
  width: 6px;
  border-radius: 12px;
}

.finanacialsImages .imgDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  width: 6px;
  border-radius: 12px;
}

.finanacialsImages .imgDiv::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  width: 6px;
  border-radius: 12px;
}

.finanacialsImages .imgDiv img {
  width: 100%;
  height: auto;
}

.mapsContiner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.addressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  width: 100%;
  margin-top: 0.5em;
  font-size: 1.2em;
  color: var(--main-color);
}

.mapContainer {
  width: 80%;
  height: 422px;
  margin: 20px 0;
  border-radius: 20px;
  overflow: hidden;
}

.businessHighlightsMainDiv {
  padding: 56px 0 !important;
}

.mapTitle {
  padding: 8px;
  margin-left: 1em;
  color: var(--main-color);
}

.finanacialsSingleImages {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 50px;
  margin-top: 20px;
  justify-content: center;
}

.finanacialsSingleImages a {
  width: 100%;
  height: 100%;
}

.finanacialsSingleImages .imgDiv {
  width: 70%;
  /* height: 300px; */
  cursor: pointer;
  /* overflow-y: auto; */
}

.imgDiv img {
  width: 100%;
  height: 100%;
}

.disclaimer {
  text-align: left;
  display: block;
}

.demographics {
  text-align: left;
  display: block;
  margin: 5px 25px;
  font-size: 20px;
}

.swotBody {
  margin: 10px 50px;
}

.elementContainer {
  padding: 0 10px;
  min-width: 200px;
}

.elementCell {
  padding: 0;
  min-width: 350px;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.elementTitle {
  font-size: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
}

.elementBody {
  padding: 20px;
  height: 150px;
  overflow-y: auto;
  width: 100%;
}

.elementItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.elementItem div {
  width: 100%;
}

.elementFooter {
  height: 5px;
}

/* financial csv */
@media screen and (max-width: 1440px) {
  .circleIconWithTitle > div {
    width: 90px;
    height: 90px;
  }

  .circleIconWithTitle svg {
    font-size: 50px;
  }

  .circleIconWithTitle p {
    font-size: 16px;
  }

  .page h3 {
    font-size: 45px;
  }
}

@media screen and (max-width: 1140px) {
  .mainContainer {
    /* max-width: 96% !important; */
  }

  .statsContainer p {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 1024px) {
  .contactUs .imgDiv {
    width: 170px;
    height: 170px;
  }
}

@media screen and (max-width: 991px) {
  .heroHeader {
    font-size: 48px;
  }

  .statsEmptyCol {
    display: inline;
    margin: 0;
    padding: 0;
  }

  .csvTable {
    overflow-x: scroll;
  }

  .csvTable table {
    width: 950px;
  }

  .socailCard {
    flex-wrap: wrap;
    justify-content: center;
  }

  .socailCard > div {
    flex-basis: 33%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .mainContainer {
  }

  .heroHeader {
    font-size: 38px;
  }

  .prosCol {
    margin-bottom: 24px;
  }

  .contactUs .details {
    flex-direction: column;
  }

  .contactUs .details .imgDiv {
    margin: 0px auto;
  }

  .contactUs .details .right h5 {
    margin-top: 20px;
  }

  .socailCard {
    flex-wrap: wrap;
    justify-content: center;
  }

  .socailCard > div {
    flex-basis: 50%;
    margin-bottom: 20px;
  }

  .contactUs .right .contactInfo {
    margin: 0 0 10px 0;
  }

  .contactUs .details .right {
    margin-left: 0px;
  }

  .page h3 {
    font-size: 32px;
  }

  .description {
    font-size: 14px;
  }

  .contactUs .details .right h5 {
    text-align: center;
  }
}

@media screen and (max-width: 578px) {
  .mainContainer {
  }

  .statsContainer p {
    font-size: 22px !important;
  }

  .statsContainer div {
    width: 90px !important;
    height: 90px !important;
  }

  .statsContainer svg {
    font-size: 45px !important;
  }

  .finanacialsImages {
    justify-content: center;
  }

  .contactUs .details .right h5 {
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .socailCard > div {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .mapContainer {
    width: 90%;
  }

  .contactUs .details .right h5 {
    text-align: center;
  }
}

/*  */
