.tab {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.tab a {
  color: #00000099;
  padding: 12px 16px;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 2px solid transparent;
}

.tab a:hover {
  color: #00000099;
}

.activeTab {
  color: #1976d2 !important;
  border-bottom: 2px solid #1976d2 !important;
}
