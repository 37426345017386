.folderDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  padding: 5px 8px 6px 8px;
  box-shadow: 0 0 2px #0000005e;
  min-height: 116px;
  justify-content: center;
  position: relative;
}

.folderName {
  font-family: "Open-Sans-medium";
  font-size: 15px;
  color: var(--dashboard-text-color);
  text-transform: capitalize;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
}

.folderDiv svg {
  width: 100%;
  text-align: center;
}

.folderDiv .btnsDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: 6px;
  gap: 6px;
}



.folderDiv .btnsDiv .icon {
  background-color: var(--dashboard-main-color);
  padding: 6px;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px #afa8a8;
}

.checkbox {
  /* background-color: var(--dashboard-main-color); */
  /* padding: 2px; */
  /* border-radius: 7px; */
  color: var(--dashboard-main-color);
  cursor: pointer;
  position: absolute;
  top: 6px;
  left: 6px;

  /* width: 30px; */
  /* box-shadow: 0px 0px 3px 1px #afa8a8; */
}

.folderDiv .btnsDiv .icon svg {
  color: var(--white-color);
  vertical-align: middle;
  width: 14px;
  height: 14px;
  display: inherit;
}