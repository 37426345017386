.pdfViewer {
  width: 50%;
}

.pdfContainer {
  height: calc(100vh - 300px);
  overflow: scroll;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.icon {
  cursor: pointer;
}

.icon:hover {
  opacity: 0.8;
}