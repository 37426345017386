.container {
  min-height: 250px;
  max-height: calc(100vh);
}

.header {
  display: flex;
  justify-content: flex-end;
  font-size: 2em;
  margin: 0.1em 0 0.1em 0;
}

.closeBtn {
  cursor: pointer;
}

.closeBtn:hover {
  color: grey;
}

.content {
  text-align: left;
  margin-bottom: 10px;
  padding: 5px;
  min-height: 300px;
  max-height: calc(100vh - 300px);
  max-width: 1000px;
  min-width: 600px;
  overflow-y: scroll;
  position: relative;
} 

.card {
  padding: 0 0.5em;
  margin: 0.5em 0;
  border: solid 1px grey;
  border-radius: 5px;
}

.cardHeader {
  font-size: 1.2em;
  cursor: pointer;
}

.cardContent {
  padding-left: 1em;
  margin-top: 0.5em;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
  color: var(--dashboard-main-color);
  cursor: pointer;
}

.timeContainer {
  font-size: 0.8em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
}

.companyContainer {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.footer {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnDisable {
  color: grey
}
