.container-table100 {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 33px 30px;
}

.wrap-table100 {
  width: 1170px;
}

table {
  width: 100%;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
}

.column1 {
  width: 20%;
  padding-left: 40px;
}

.column2 {
  width: 20%;
}

.column3 {
  width: 20%;
}

.column4 {
  width: 20%;
}

.column5 {
  width: 20%;
}

.table100-head th {
  padding-top: 18px;
  padding-bottom: 18px;
}

.table100-body td {
  padding-block: 15px;
}

.table100 {
  position: relative;
  padding-top: 60px;
}

.table100-head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.table100-body {
  max-height: 70vh;
  overflow: auto;
}

.table100.ver1 th {
  font-family: "Open-Sans-medium";
  color: var(--white-color);
  font-size: 14px;
}

.table100.ver1 td {
  font-family: "Open-Sans-medium";
  font-size: 14px;
  color: var(--dashboard-text-color);
  text-transform: capitalize;
}

.table100.ver1 .table100-body tr {
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 5px 25px #52575d1a;
  background-color: var(--white-color);
}

.table100 .table100-head tr {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: var(--dashboard-main-color);
  border-radius: 10px;
}

.table100.ver1 {
  border-radius: 10px;
  overflow: hidden;
}

.table100 .table100-foot tr {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 3px 0;
  background-color: var(--dashboard-main-color);
  border-radius: 10px;
  min-height: 50px;
  margin: 0 15px;
}