.mainContainer {
  height: calc(100vh - 160px);
  overflow-y: scroll;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 15px 0 15px;
}

.headerContainer h4 {
  color: black;
  font-size: 35px !important;
  font-family: 'Bebas-Neue-bold' !important;
}

.folderBox {
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
}

.folderInnerBox {
  width: calc(33.33% - 12px);
  margin-bottom: 16px;
}

.mainFoldersDiv {
  background: #fff;
  padding: 18px;
  border-radius: 16px;
  box-shadow: 0 0 4px #e9ecef;
  margin-bottom: 32px;
}

@media screen and (max-width: 1200px) {
  .folderInnerBox {
    width: calc(50% - 8px);
  }
}
