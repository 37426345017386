.container {
  padding: 0 35px;
}

.container h6 {
  margin-bottom: 0px;
}

.inputContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.row {
  row-gap: 20px;
}

.mb12 {
  margin-bottom: 12px;
}

.addMore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 125px;
  border-radius: 10px;
  box-shadow: 0px 0 5px 2px #0000000d;
}

.addMore p {
  margin-bottom: 0px;
}

.allowedFormat {
  font-size: 14px;
  color: var(--dashboard-text-color);
  margin-bottom: 0px;
}
