.add_task_main {
  padding: 0 20px;
}
.add_task_btn {
  width:100%;
  background-color: var(--dashboard-main-color);
  font-family: "Open-Sans-bold" !important;
  font-size: 18px !important;
  padding: 10px 0;
}
.add_task_main h4 {
  color: var(--dashboard-main-color);
  font-family: "Open-Sans-bold" !important;
  text-align: center;
  margin-bottom: 30px;
}
.input_main {
  margin-top: 20px;
}
.add_task_btn_main {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.BSTimePicker {
  padding: 1em;
  font: inherit;
  box-shadow: 0px 0 5px 2px #0000000d;
  border: none;
}