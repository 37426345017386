.textAreaBox {
  position: relative;
}

.label {
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: "Open-Sans-bold";
  font-size: 16px;
  margin-bottom: 10px;
}

.textAreaBox textarea {
  padding: 16px;
  font-size: 16px;
  font-family: "Open-Sans-regular";
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  background-color: var(--white-color);
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
}

.customTextArea {
  padding: 16px;
  font-size: 16px;
  font-family: "Open-Sans-regular";
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  background-color: var(--white-color);
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
}

.textAreaBox textarea::placeholder {
  color: var(--placeholder-color);
  font-family: "Open-Sans-medium";
  font-size: 16px;
  text-transform: capitalize;
}

.textAreaBox textarea:disabled {
  background-color: var(--disabled-input-color);
  color: #c9c9c9;
  user-select: none;
}

.labelDisabled {
  color: var(--disabled-label-color);
}

.companyContainer {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: var(--dashboard-main-color);
}
