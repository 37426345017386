.mainContainer {
  padding: 15px;
  height: calc(100vh - 103px);
}

.mainHeading {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
}

.mainHeading h4 {
  font-family: 'Bebas-Neue-bold';
  font-size: 35px;
  color: #242342;
  margin-bottom: 0;
}

.actions_btn {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.detail_btn {
  padding: 10px;
}

.edit_btn {
  padding: 8px 20px;
  background-color: transparent;
  color: var(--text-color-black);
  border: 1px solid #d4d4d4;
}

.paginationDiv {
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px 20px 30px;
}

.teamMemberImgDiv {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  border: 1px solid #707070;
  position: absolute;
  top: -22px;
}

.teamMemberImgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.countMember {
  position: absolute;
  left: 90px;
  top: -7px;
  font-size: 12px;
}
