body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.jodit-status-bar-link {
  display: none !important;
}

.check_box_container {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;

}

/* Hide the browser's default checkbox */
.check_box_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid #4d4d4d;
}

/* When the checkbox is checked, add a blue background */
.check_box_container input:checked ~ .checkmark {
  background-color: var(--main-color);
  border: 0px solid transparent;
}
.check_box_container input:disabled ~ .checkmark {
  background-color: transparent;
  border: 2px solid #dddddd80;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_box_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_box_container .checkmark:after {
  left: 6px;
  right: 6px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.chooseOneOption {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.chooseOneOptionItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.chooseOneOptionItem label {
  color: black;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
}