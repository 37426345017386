.addMoreBtnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
  background-color: var(--action-btn-color);
}

.addMoreBtnContainer:hover {
  background-color: #6bb3f1;
}

.addMoreBtnContainer span {
  color: white;
  font-family: "Open-Sans-regular" !important;
  margin: 0px 5px;
  font-size: 13px;
}
