.popperDiv {
  text-align: center;
  border-radius: 10px !important;
  background: var(--white-color) !important;
  box-shadow: 0 0 8px 4px #00000014 !important;
  padding: 8px 24px;
}
.popperDiv ul {
  padding: 0;
}
.popperDiv ul li {
  font-size: 14px;
  color: #8f9299;
  padding: 0 0 6px 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: var(--white-color) !important;
}
.popperDiv ul li:hover {
  background: var(--white-color) !important;
}
